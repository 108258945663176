const FILLS = [
  'More Kicks',
  'Less Kicks',
  'More Snare',
  'More Crash',
  'More Ride',
  'More Hats',
  'Less Hats',
  'All the Toms!',
  'Sparse',
  'Kitchen Sink',
  'Freestyle',
];

export default FILLS;
